import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div id="about"></div>
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`I am the Founder of `}<a href="https://dashcam.bike">{`dashcam.bike`}</a>{`, a small business building tools to make streets safer for city cyclists.`}</p>
    </blockquote>
    <p>{`I founded and continue to lead `}<a href="https://rcvis.com">{`RCVis`}</a>{`, which has been used by millions of voters worldwide to better understand the results of Ranked Choice Voting elections.`}</p>
    <p>{`Previously, I helped humans and self-driving cars communicate with graphics & visualizations at `}<a href="https://www.argo.ai">{`Argo AI`}</a>{`, leading the Visualization, Remote Guidance, and User Experience teams.`}</p>
    <p>{`Prior to 2019, I worked on fashion-technology at `}<a href="http://www.avametric.com">{`Avametric`}</a>{`, a virtual fitting room startup that helps you choose the best size for your body. Prior to 2015, I worked on Photoshop `}<a href="http://www.adobe.com/products/fix.html">{`Fix`}</a>{` and `}<a href="http://www.adobe.com/products/mix.html">{`Mix`}</a>{` at Adobe, shipping some of the first mobile Photoshop apps to millions of users.`}</p>
    <p>{`I received my Master's from the `}<a href="http://graphics.berkeley.edu/">{`Visual Computing Lab`}</a>{` at U.C. Berkeley, advised by `}<a href="http://obrien.berkeley.edu/">{`James O'Brien`}</a>{` and funded by the `}<a href="http://www.nsfgrfp.org/">{`NSF GRFP`}</a>{`. I received my Bachelor's from U.C. Santa Cruz, advised by `}<a href="http://users.soe.ucsc.edu/~davis/">{`James Davis`}</a>{`.`}</p>
    <p>{`I live in the bridgiest city in the world: Pittsburgh, PA. I volunteer with `}<a href="https://mohpa.org">{`March on Harrisburg`}</a>{` and `}<a href="https://twitter.com/LVbikeped">{`Better Streets Lawrenceville`}</a>{`.`}</p>
    <p>{`Email me at `}<a parentName="p" {...{
        "href": "mailto:hello@arminsamii.com"
      }}>{`hello@arminsamii.com`}</a>{`.`}</p>
    <p>Last updated: Sep 2023</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      