import React, { useState } from 'react';

type ProjectCardTagSelectorProps = {
  tags: list // all tags
  currentTag  // curent tag
  setCurrentTag // setter for curent tag
}

const ProjectCardTagSelector = ({tags, currentTag, setCurrentTag}: ProjectCardTagSelectorProps) => {
    return (<div style={{textAlign: 'left'}}>
    <div style={{marginBottom:10}}>Showing:</div>
    <div>
    {tags.map((tag, index) => {
        const bgColor = tag == currentTag ? 'yellow' : 'white';
        const fontWeight = tag == currentTag ? 'bold' : 'normal';
        return <>
            <span>
                <button onClick={() => setCurrentTag(tag)}
                        style={{padding: 10,
                        border: 'none',
                        width: 125,
                        borderRadius:10,
                        marginBottom: 10,
                        marginRight: 20,
                        fontSize: '0.8em',
                        backgroundColor: bgColor,
                        boxShadow: '-10px -10px 25px #00000035 inset',
                        fontWeight: fontWeight }}> {tag}
                </button></span>
        </>
    })}
    </div>
    </div>)
}

export default ProjectCardTagSelector
