/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { hidden } from "@lekoarts/gatsby-theme-cara/src/styles/utils"

class Cat1 extends React.Component {
  render() {
    return (
      // https://codepen.io/hmig/pen/begvBM
      <React.Fragment>
        <svg version="1" viewBox="0 0 720 800">
          <path data-name="tail" class="cls-1" d="M545.9,695.9c8,28.2,23.2,42.3,27.2,46.9,21.4,24.1,41.5,40.2,81.1,42.9s65.4-14.2,60.8-26.8-23.1-9.1-51.3-8.3c-35.2.9-66.6-31.3-74.8-63.9s-7.9-63.8-36.8-85.5c-44.1-33-135.6-7.1-159.8-3.4s-48.4,52.5-9.6,45.1,91.4-23.1,123.2-12.7C537.8,640.4,537.9,667.7,545.9,695.9Z" transform="translate(-9.7 -9.3)"/>
          <g>
            <path class="cls-2" d="M447.9,502.1c2.1,151.7-108.3,167-216.5,167S9.7,663.8,9.7,510.9,85,242.9,231.3,241,445.8,350.4,447.9,502.1h0Z" transform="translate(-9.7 -9.3)"/>
            <g>
              <path class="cls-1" d="M195.6,671.5c-34.2-7.7-40.6-95.6-53.3-191-12-90-90.1-177.2-55.1-177.2s145.7,12,151.4,87.7S261.5,686.5,195.6,671.5Z" transform="translate(-9.7 -9.3)"/>
              <path class="cls-3" d="M172.2,688.1c31.6,2.1,56.6-8.7,59.8-32.4s-22.1-49.5-27.3-24.3c25-16.4-39.1-29.4-27.6-3.9,14-24.9-49.6-19.2-31.9-.1-6.5-27.2-35.6,8.2-30.1,29.3C121.5,681.8,140.5,686,172.2,688.1Z" transform="translate(-9.7 -9.3)"/>
            </g>
            <g>
              <path data-name="leg" class="cls-1" d="M260.4,670.4c42.4-9.2,48.7-87.7,53.9-185.2,5.1-96,98.2-176.1,63.1-176.1s-164,15.7-164,111.8C213.4,420.9,199.1,683.7,260.4,670.4Z" transform="translate(-9.7 -9.3)"/>
              <path data-name="foot" class="cls-3" d="M279.4,689.8c-31.7,2-56.6-9-59.6-32.6s22.3-49.4,27.4-24.1c-24.9-16.5,39.2-29.2,27.6-3.8-13.9-25,49.7-18.9,31.9,0,6.6-27.1,35.6,8.4,30,29.4-6.7,25-25.7,29.1-57.3,31.1h0Z" transform="translate(-9.7 -9.3)"/>
            </g>
            <path class="cls-3" d="M80,331.2c3.5,9.5,1.2,28.9,4.3,32.7s31.5-30,43-20.6c10.7,8.7,1.7,55.9,12.9,64.5,10.1,7.7,32.1-50.6,52.5-38.7,24.9,14.6,34.1,49.9,49,49.9,18.3,0,7.5-49.5,24.1-53.3s46.1,52.6,60.2,45.6c4.8-2.4,3-50.4,12-57.6,8.7-6.9,30.5,22.4,33.5,18.9,3.7-4.1.1-23.1,8.6-36.1,3.4-5.2,18.9-2.6,28.8-.4a3.46,3.46,0,0,0,3.7-5.2c-19.6-30.8-100-147.4-184.2-147.4-93.3,0-150.9,86.8-178.1,141.6a3.43,3.43,0,0,0,3.6,4.9C63,328.4,78.4,326.6,80,331.2Z" transform="translate(-9.7 -9.3)"/>
          </g>
          <g>
            <path class="cls-4" d="M367,231.1c5.7,36.1-4.7,71-97.8,85.6s-184-18.5-189.7-54.5,16.7-17.3,109.8-31.9,172-35.3,177.7.8" transform="translate(-9.7 -9.3)"/>
            <g data-name="bg">
              <path class="cls-1" d="M362.5,229.5C339.7,279,273.1,299.4,225,300c-60.6.7-134.7-29.5-153.5-86.4C45.6,135.4,132.2,32.6,225,35.8c96.1,3.4,171.7,119.4,137.5,193.7" transform="translate(-9.7 -9.3)"/>
              <path class="cls-5" d="M362.5,229.5C339.7,279,273.1,299.4,225,300c-60.6.7-134.7-29.5-153.5-86.4C45.6,135.4,132.2,32.6,225,35.8,321.1,39.2,396.7,155.2,362.5,229.5Z" transform="translate(-9.7 -9.3)"/>
            </g>
            <g>
              <path class="cls-1" d="M92.7,117c-2.6,4.7-14.7-16.1-16.5-45-3.3-27.7,3.7-63.4,5.4-62C80.7,8,117,10,143,20c27.5,8.9,44.7,25.7,39.5,27.1-30,23.4-59.9,46.6-89.8,69.9" transform="translate(-9.7 -9.3)"/>
              <path class="cls-6" d="M105.8,106.9C103.9,110.3,95.3,95.5,94,75c-2.3-19.6,2.6-44.9,3.8-44-0.6-1.4,25.1,0,43.6,7.1,19.5,6.3,31.7,18.2,28,19.2q-31.8,24.9-63.6,49.6" transform="translate(-9.7 -9.3)"/>
            </g>
            <path class="cls-2" d="M338.4,142.5c-2.2,3.3,19.4,19.6,17.2,23.2s-24.3-7.8-25.8-5.2c-1.9,3.3,33.4,24.1,31,29.2-2.3,4.9-34-14.4-84.3-18.1a141.76,141.76,0,0,1-16.4-2.1,91.21,91.21,0,0,1-13.7-3.9c-19.8-6.9-27.7-10.6-32.7-12-19.3-5.7-26.8,11.3-68.1,22.4-18.8,5-37.9,9.7-54.4,0-2.1-1.3-13.6-8.3-16.7-21.1-0.9-3.6-2.8-15.2,10.5-34C146.3,34.3,216.5,34,217.3,34a131.52,131.52,0,0,1,58.4,14.3c-7.6,4.9-11.2,9.5-9,10.1,21.5,16.5,43.1,33,64.6,49.5,0.9,1.7,3.6-1.3,6.3-7.3,19.3,30.5,22.1,41.5,18.9,44.3-3.8,3.6-16.4-4.8-18.1-2.4" transform="translate(-9.7 -9.3)"/>
            <g>
              <path data-name="outer" class="cls-2" d="M344.9,119.9c2.6,4.7,14.7-16.1,16.5-45,3.3-27.7-3.7-63.4-5.4-62,0.9-2-35.4,0-61.4,10-27.5,8.9-44.7,25.7-39.5,27.1q44.85,35,89.8,69.9" transform="translate(-9.7 -9.3)"/>
              <path data-name="inner" class="cls-6" d="M343.5,76.2a77.83,77.83,0,0,1-5.6,24.6c-15.1-20.3-36-39.8-61-52.4a82,82,0,0,1,19.2-9.1c18.5-7.1,44.2-8.5,43.6-7.1,1.2-.9,6.1,24.4,3.8,44" transform="translate(-9.7 -9.3)"/>
            </g>
            <g>
              <path class="cls-7" d="M205.1,201.8l-10.6-18.3a9,9,0,0,1,7.7-13.4h21.2a8.9,8.9,0,0,1,7.7,13.4l-10.6,18.3a8.91,8.91,0,0,1-15.4,0" transform="translate(-9.7 -9.3)"/>
              <path class="cls-6" d="M194.2,175.1a9,9,0,0,0,.3,8.4l10.6,18.3a8.92,8.92,0,0,0,15.5,0l8.7-15c-5.8-6.2-19.3-10.1-35.1-11.7" transform="translate(-9.7 -9.3)"/>
            </g>
            <g>
              <path class="cls-8" d="M166.7,260.4c-24.4,0-44.1-25-44.1-55.9m88.2,0c0,30.9-19.7,55.9-44.1,55.9m89.9,0c24.4,0,44.1-25,44.1-55.9m-88.2,0c0,30.9,19.7,55.9,44.1,55.9" transform="translate(-9.7 -9.3)"/>
              <path class="cls-9" d="M300.7,204.5a65.16,65.16,0,0,1-8,32" transform="translate(-9.7 -9.3)"/>
            </g>
            <path class="cls-10" d="M188.7,198.4c0-12.9-72.7-23.3-162.6-23.3m162.6,36.2c0-7.1-65.8-12.9-147.1-12.9m196,1.3c1.4-12.8,74.8-15.6,164.1-6.2m-165.4,19c0.7-7.1,66.8-5.9,147.6,2.6" transform="translate(-9.7 -9.3)"/>
            <g class="eye">
              <path class="cls-4" d="M188.6,141.5s-18.3,12.3-35.8,7.9-30-15.2-27.7-24c1.5-6,9.6-9.6,20.2-9.8a59.5,59.5,0,0,1,15.7,1.9,35.75,35.75,0,0,1,12.5,6.2,60,60,0,0,1,15.1,17.8" transform="translate(-9.7 -9.3)"/>
              <path class="cls-11" d="M125.1,123.6c1.5-6,9.6-9.6,20.1-9.8a59.5,59.5,0,0,1,15.7,1.9,35.75,35.75,0,0,1,12.5,6.2,59.47,59.47,0,0,1,15.2,17.8" transform="translate(-9.7 -9.3)"/>
              <path class="cls-12" d="M172.9,124.3c-2.3,9.2-10.7,15-18.7,13s-12.5-11.1-10.2-20.4a22.39,22.39,0,0,1,1.1-3.1,59.5,59.5,0,0,1,15.7,1.9,35.75,35.75,0,0,1,12.5,6.2,8.6,8.6,0,0,1-.4,2.4" transform="translate(-9.7 -9.3)"/>
              <path class="cls-13" d="M124.9,121.5c-7.6,2.6-17.1-4.7-21.1-16.3m33.6,9.5c-7.5,2.9-17.3-4-21.7-15.5m36.7,14.6c-8.1-.1-14.5-10.2-14.3-22.6" transform="translate(-9.7 -9.3)"/>
              <path class="cls-14" d="M156.8,122c0,3.6-2.6,6.4-5.8,6.4s-5.8-2.9-5.8-6.4,2.6-6.4,5.8-6.4,5.8,2.9,5.8,6.4" transform="translate(-9.7 -9.3)"/>
            </g>
            <g class="eye">
              <path data-name="iris" class="cls-4" d="M241.4,143.6s18.5,11.9,36,7.1,29.6-15.8,27.2-24.6c-1.7-6-9.8-9.4-20.3-9.4a59.21,59.21,0,0,0-15.6,2.2,37.44,37.44,0,0,0-12.4,6.4,60.14,60.14,0,0,0-14.9,18.3" transform="translate(-9.7 -9.3)"/>
              <path class="cls-11" d="M304.5,124.4c-1.7-6-9.8-9.4-20.3-9.4a59.21,59.21,0,0,0-15.6,2.2,37.44,37.44,0,0,0-12.4,6.4,61.21,61.21,0,0,0-14.9,18.1" transform="translate(-9.7 -9.3)"/>
              <path data-name="pupil" class="cls-12" d="M256.7,126.1c2.5,9.2,11,14.8,18.9,12.6s12.3-11.4,9.8-20.6a16.59,16.59,0,0,0-1.2-3.1,59.21,59.21,0,0,0-15.6,2.2,37.44,37.44,0,0,0-12.4,6.4,9.23,9.23,0,0,0,.5,2.5" transform="translate(-9.7 -9.3)"/>
              <path data-name="eyelash" class="cls-13" d="M302.9,122.3c7.7,2.5,17-5,20.8-16.8M292,115.7c7.6,2.8,17.2-4.4,21.4-16M277,115.1c8.1-.3,14.3-10.5,13.9-22.8" transform="translate(-9.7 -9.3)"/>
              <path data-name="reflection" class="cls-14" d="M271.1,127.1c0,3.6-2.6,6.5-5.8,6.5s-5.8-2.9-5.8-6.5,2.6-6.4,5.8-6.4,5.8,2.9,5.8,6.4" transform="translate(-9.7 -9.3)"/>
            </g>
          </g>
        </svg>
      </React.Fragment>
    )
  }
};

class Cat2 extends React.Component {
  render() {
    return (
      // https://codepen.io/jasoncluck/pen/oyHdE
      <React.Fragment>
        <svg version="1" viewBox="0 0 640 480">
         <g>
          <circle class="ball" r="13.53787" cy="287.50003" cx="279.99998"/>
          <path class="cat" d="m396.35663,311.25821c-13.41742,-10.47318 -28.76758,-3.96683 -43.5509,-4.75632c-12.93182,-11.07962 -28.66629,2.3475 -40.85934,-8.33789c-15.77493,-14.05475 11.56558,-22.86807 5.7373,-39.56415c-0.65771,-24.0419 -10.94836,-45.81717 -21.77539,-66.02428c-7.65988,-16.06839 -4.94803,-34.53508 -7.41699,-51.7166c-8.85388,-12.34473 -17.69766,-27.62405 -7.16412,-42.87289c11.90973,-12.87106 6.30396,-29.77943 1.62924,-44.65391c12.03101,3.54156 25.85321,19.66071 41.68884,16.43285c10.75632,-6.93137 17.29297,-0.08103 19.09409,12.61797c5.64624,16.29109 8.93484,38.5827 26.75375,43.83432c18.70941,5.34254 39.6553,6.52644 54.44867,22.20027c27.06769,25.3474 39.60474,65.31624 38.552,103.83789c2.43921,18.01105 25.04413,8.44919 36.23547,11.10004c18.74976,-0.82904 36.76111,5.06967 54.91443,9.1069c16.20013,4.31042 29.92078,-9.31888 45.14905,-10.9483c2.57037,15.745 -17.41418,22.30164 -28.33221,27.15833c-12.95184,5.51471 -26.98676,5.64691 -40.29266,1.6095c-28.78772,-7.18442 -59.29565,-5.29221 -86.63629,7.31573c-14.76294,5.44357 -29.09103,15.73447 -45.14957,14.43954l-3.02536,-0.77902l0,0l0,0z" />
         </g>
        </svg>
      </React.Fragment>
    )
  }
};

class Cat3 extends React.Component {
  render() {
    return (
      // https://codepen.io/miukimiu/pen/Igouf
      <React.Fragment>
        <svg version="1.1" viewBox="0 0 400 500">
           <g width="72" height="79">
             <path d="M164.7,376.7l6.2,9.9c0,0-15.1,13-33.7,16.8c-13.4,2.8-61.1,6.4-61.1-41.8
               s33.6-56.2,18.4-15.5c0,0-5.5,17.1,1.6,28.6s27.4,21.7,45.8,12.2L164.7,376.7z" width="72" height="79"/>
           </g>
           <g>
             <g>
               <path d="M242.2,95L242.2,95l-0.1-0.1C242.1,94.9,242.1,95,242.2,95z"/>
               <path d="M111.9,78.4L111.9,78.4C128.4,67.2,140,37.8,140,37.8l10.4,9.1c0,0-3-34.6,5.2-26.4s30.7,26,30.7,26
                 s-4.1-21.9,0-17.8c4,4,20.8,38.6,53.5,64.5c-18.5-14.5-42.9-28.1-74-41.4C144.8,60.8,127,69.5,111.9,78.4z"/>
               <g>
                 <path d="M242,94.9c-0.8-0.6-1.5-1.2-2.2-1.7C240.5,93.7,241.3,94.3,242,94.9z"/>
                 <path d="M243.1,95.8c0.5-0.6,0.4-1.5-0.2-2l0,0c-0.7-0.6-1.5-1.2-2.2-1.8l0,0c0,0,0,0-0.1,0l0,0l0,0l0,0l0,0l0,0
                   l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0c-0.4-0.2-0.8-0.2-1.1-0.1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0
                   l0,0l0,0l0,0c0,0,0,0-0.1,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0c0,0,0,0,0,0.1l0,0
                   c-0.2,0.3-0.3,0.6-0.3,1l0,0l0,0l0,0c0,0.4,0.2,0.7,0.5,0.9l0,0c0.1,0.1,0.2,0.2,0.3,0.3l0,0c0.6,0.5,1.3,1,1.9,1.5l0,0l0.9-1.1
                   l-0.9,1.1c0.3,0.2,0.6,0.3,0.9,0.3l0,0C242.5,96.3,242.9,96.1,243.1,95.8L243.1,95.8z"/>
               </g>
             </g>
           </g>
           <g>
             <g>
               <path d="M158.8,95.7L158.8,95.7l0.1-0.1C158.8,95.7,158.8,95.7,158.8,95.7z"/>
               <path d="M289,79.2L289,79.2c-16.4-11.3-28.1-40.7-28.1-40.7l-10.4,9.1c0,0,3-34.6-5.2-26.4s-30.7,26-30.7,26
                 s4.1-21.9,0-17.8c-4,4-20.8,38.6-53.5,64.5c18.5-14.5,42.9-28.1,74-41.4C256.1,61.5,274,70.2,289,79.2z"/>
               <g>
                 <path d="M158.9,95.6c0.8-0.6,1.5-1.2,2.2-1.7C160.4,94.5,159.6,95.1,158.9,95.6z"/>
                 <path d="M157.8,96.5c-0.5-0.6-0.4-1.5,0.2-2l0,0c0.7-0.6,1.5-1.2,2.2-1.8l0,0c0,0,0,0,0.1,0l0,0l0,0l0,0l0,0l0,0
                   l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0c0.4-0.2,0.8-0.2,1.1-0.1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0
                   l0,0l0,0c0,0,0,0,0.1,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0c0,0,0,0,0,0.1l0,0
                   c0.2,0.3,0.3,0.6,0.3,1l0,0l0,0l0,0c0,0.4-0.2,0.7-0.5,0.9l0,0c-0.1,0.1-0.2,0.2-0.3,0.3l0,0c-0.6,0.5-1.3,1-1.9,1.5l0,0
                   l-0.9-1.1l0.9,1.1c-0.3,0.2-0.6,0.3-0.9,0.3l0,0C158.5,97,158.1,96.9,157.8,96.5L157.8,96.5z"/>
               </g>
             </g>
           </g>
           <path d="M241.2,276.8c0,0,54.5,100.7,3.9,105.1"/>
           <path d="M158.7,276.8c0,0-54.5,100.7-3.9,105.1"/>
           <path d="M199.4,276.8H241c0,0,43.8,128.4-12,127.7h-29.6"/>
           <path d="M200.1,276.8h-41.3c0,0-43.8,128.4,12,127.7h29.4"/>
           <path d="M265.1,65.2c0,0,43-15.3,48.9-13.1c5.8,2.2,13.9,25.5,0,48.2"/>
           <path d="M287.7,69.5c0,0,36.5-17.5,25.5,17.5"/>
           <path d="M199.3,278.6c5.2-0.7,161.4,11.2,151.9-109S199.3,47,199.3,47"/>
           <path d="M134.8,64.4c0,0-43-15.3-48.9-13.1c-5.8,2.2-13.9,25.5,0,48.2"/>
           <path d="M114.4,68.8c0,0-36.5-17.5-25.5,17.5"/>
           <path d="M199.9,278.6c-5.2-0.7-161.4,11.2-151.9-109S199.9,47,199.9,47"/>
           <g>
             <g>
               <g>
                 <defs>
                   <path d="M245.2,228.4c0.4,15.3-18.4,22.6-46.5,22.6s-42.3-7.9-43.6-23.1c-1.8-21,15.3-18.7,43.4-18.7
                     C226.5,209.2,244.6,207.4,245.2,228.4z"/>
                 </defs>
                 <clipPath>
                   <use overflow="visible"/>
                 </clipPath>
                 <path clip-path="url(#SVGID_2_)" d="M245.2,228.4c0.4,15.3-18.4,22.6-46.5,22.6s-42.3-7.9-43.6-23.1
                   c-1.8-21,15.3-18.7,43.4-18.7C226.5,209.2,244.6,207.4,245.2,228.4z"/>
               </g>
             </g>
             <g>
               <g>
                 <defs>
                   <path d="M245.2,228.4c0.4,15.3-18.4,22.6-46.5,22.6s-42.3-7.9-43.6-23.1c-1.8-21,15.3-18.7,43.4-18.7
                     C226.5,209.2,244.6,207.4,245.2,228.4z"/>
                 </defs>
                 <clipPath>
                   <use overflow="visible"/>
                 </clipPath>
                 <path clip-path="url(#SVGID_4_)" d="M159.4,250.1c-0.3-13.3,16-19.6,40.3-19.6s36.6,6.8,37.8,20
                   c1.6,18.2-13.2,16.2-37.6,16.2C175.6,266.7,159.9,268.3,159.4,250.1z"/>
               </g>
             </g>
           </g>
           <path d="M209.4,398c-2.5,6.3-4.6,12.7-4.9,19.5c-0.5,9.5,2,18.9,5.2,27.7c1.7,4.6,12.8,1.7,11.2-2.8
             c-2.9-8.1-5.3-16.6-5.1-25.2c0.2-6.3,2-12.3,4.4-18.1c2.2-5.5-8.1-5.3-9.8-1.1H209.4z"/>
           <path d="M194.2,396.7c-2.9,15.4-8.3,33-0.3,47.8c1.3,2.4-3.7,2.9-4.9,3.1c-2.8,0.3-6.2-0.9-7.7-3.5
             c-8.5-15.7,0.2-35.5,3.4-52C185.1,389.9,194.8,393.4,194.2,396.7z"/>
           <path fill="#ffffff50" d="M212.1,167.6c-2.7-5.2-22.5-5.5-24.6,0c-1.3,3.6,7.6,11,12.1,10.8C204.2,178.1,213.7,170.7,212.1,167.6z"/>
           <path fill="#ffffff50" d="M230.5,136.4c3.4-6,6.5-9.9,9.2-12.1l0,0c2.7-2.3,4.9-2.9,6.8-2.9l0,0c3,0,5.9,2,8.3,4.7l0,0
             c2.4,2.7,4.1,5.8,4.6,7l0,0c0.2,0.3,0.2,0.5,0.2,0.5l0,0l0,0c0.6,1.4,2.3,2.1,3.8,1.5l0,0c1.4-0.6,2.1-2.3,1.5-3.8l0,0
             c-0.1-0.2-3.5-8.3-10.3-13l0,0c-2.3-1.5-5-2.7-8.2-2.7l0,0c-3.4,0-7,1.4-10.4,4.2l0,0c-3.5,2.9-7,7.3-10.5,13.8l0,0
             c-0.8,1.4-0.3,3.1,1.1,3.9l0,0c0.4,0.2,0.9,0.4,1.4,0.4l0,0C229,137.9,230,137.3,230.5,136.4L230.5,136.4z"/>
           <path fill="#ffffff50" d="M140.3,136.4c3.4-6,6.5-9.9,9.2-12.1l0,0c2.7-2.3,4.9-2.9,6.8-2.9l0,0c3,0,5.9,2,8.3,4.7l0,0
             c2.4,2.7,4.1,5.8,4.6,7l0,0c0.2,0.3,0.2,0.5,0.2,0.5l0,0l0,0c0.6,1.4,2.3,2.1,3.8,1.5l0,0c1.4-0.6,2.1-2.3,1.5-3.8l0,0
             c-0.1-0.2-3.5-8.3-10.3-13l0,0c-2.3-1.5-5-2.7-8.2-2.7l0,0c-3.4,0-7,1.4-10.4,4.2l0,0c-3.5,2.9-7,7.3-10.5,13.8l0,0
             c-0.8,1.4-0.3,3.1,1.1,3.9l0,0c0.4,0.2,0.9,0.4,1.4,0.4l0,0C138.7,137.9,139.7,137.3,140.3,136.4L140.3,136.4z"/>
           <path fill="#ffffff50" d="M176.8,290.6c0,0,9.5,4.4,23.3,5.1v94.9C200.1,390.6,143.9,397.1,176.8,290.6z"/>
           <path fill="#ffffff50" d="M223.5,290.6c0,0-9.5,4.4-23.3,5.1v94.9C200.1,390.6,256.3,397.1,223.5,290.6z"/>
           <g>
             <g>
               <path d="M94.8,97.2L94.8,97.2L94.8,97.2C94.7,97.3,94.7,97.2,94.8,97.2z"/>
               <path d="M78.2,227.4L78.2,227.4C67,211,37.5,199.3,37.5,199.3l9.1-10.4c0,0-33.9-45.1-25.7-53.3
                 c8.2-8.2,25.2,17.4,25.2,17.4s-21.1-44-17-48.2c4-4,37.9,27.3,63.8-5.3c-14.5,18.5-28.1,42.9-41.4,74
                 C60.5,194.5,69.3,212.4,78.2,227.4z"/>
               <g>
                 <path d="M94.7,97.3c-0.6,0.8-1.2,1.5-1.7,2.2C93.5,98.8,94.1,98,94.7,97.3z"/>
                 <path d="M95.5,96.2c-0.6-0.5-1.5-0.4-2,0.2l0,0c-0.6,0.7-1.2,1.5-1.8,2.2l0,0c0,0,0,0,0,0.1l0,0l0,0l0,0l0,0l0,0
                   l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0c-0.2,0.4-0.2,0.8-0.1,1.1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0
                   l0,0l0,0l0,0c0,0,0,0,0,0.1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0c0,0,0,0,0.1,0l0,0
                   c0.3,0.2,0.6,0.3,1,0.3l0,0l0,0l0,0c0.4,0,0.7-0.2,0.9-0.5l0,0c0.1-0.1,0.2-0.2,0.3-0.3l0,0c0.5-0.6,1-1.3,1.5-1.9l0,0l-1.1-0.9
                   l1.1,0.9c0.2-0.3,0.3-0.6,0.3-0.9l0,0C96.1,96.9,95.9,96.5,95.5,96.2L95.5,96.2z"/>
               </g>
             </g>
           </g>
           <g>
             <g>
               <path d="M302.8,99.1L302.8,99.1l0.1,0.1C302.8,99.1,302.8,99.1,302.8,99.1z"/>
               <path d="M319.3,229.3L319.3,229.3c11.3-16.4,40.7-28.1,40.7-28.1l-9.1-10.4c0,0,34.6,3,26.4-5.2s-26-30.7-26-30.7
                 s21.9,4.1,17.8,0c-4-4-38.6-20.8-64.5-53.5c14.5,18.5,28.1,42.9,41.4,74C337,196.4,328.3,214.3,319.3,229.3z"/>
               <g>
                 <path d="M302.9,99.2c0.6,0.8,1.2,1.5,1.7,2.2C304,100.7,303.5,99.9,302.9,99.2z"/>
                 <path d="M302,98.1c0.6-0.5,1.5-0.4,2,0.2l0,0c0.6,0.7,1.2,1.5,1.8,2.2l0,0c0,0,0,0,0,0.1l0,0l0,0l0,0l0,0l0,0l0,0
                   l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0c0.2,0.4,0.2,0.8,0.1,1.1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0
                   l0,0c0,0,0,0,0,0.1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0c0,0,0,0-0.1,0l0,0
                   c-0.3,0.2-0.6,0.3-1,0.3l0,0l0,0l0,0c-0.4,0-0.7-0.2-0.9-0.5l0,0c-0.1-0.1-0.2-0.2-0.3-0.3l0,0c-0.5-0.6-1-1.3-1.5-1.9l0,0
                   l1.1-0.9l-1.1,0.9c-0.2-0.3-0.3-0.6-0.3-0.9l0,0C301.5,98.8,301.6,98.4,302,98.1L302,98.1z"/>
               </g>
             </g>
           </g>
         </svg>
      </React.Fragment>
    )
  }
};

class Cat4 extends React.Component {
  render() {
    return (
      // https://codepen.io/charisseysabel/pen/adXGMe
      <svg version="1.1" viewBox="0 0 209 191">
        <g>
          <g>
            
              <path fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
              M53.667,62.864c0,0-28.5-16.031-47.667-7.864"/>
            
              <path fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
              M55.667,67.864c0,0-28.5-16.031-47.667-7.864"/>
            
              <path fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
              M59.667,72.864c0,0-28.5-16.031-47.667-7.864"/>
          </g>
        </g>
        <g>
          <g>
              <path fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
              M156,62.864c0,0,28.5-16.031,47.667-7.864"/>
            
              <path fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
              M154,67.864c0,0,28.5-16.031,47.667-7.864"/>
            
              <path fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
              M150,72.864c0,0,28.5-16.031,47.667-7.864"/>
          </g>
        </g>
        <g>
          <g>
            <path d="M163.88,20.63c0,0-58.571-8.479-109.544-0.634C27.333,40.333,33.667,187,33.667,187L192,186
              C192,186,189.38,41.38,163.88,20.63z M118.323,63.621c-2.946,4.245-6.662,4.622-11.165,2.408
              c-2.833-1.393-4.046,1.053-6.787,1.589c-2.931,0.573-6.026-0.717-8.5-2.146c-1.674-0.967-0.164-3.56,1.515-2.59
              c2.963,1.712,5.841,2.447,8.859,0.296c0.904-0.645,1.821-1.082,2.953-1.094c4.178-0.04,6.7,5.546,10.534,0.023
              C116.827,60.53,119.43,62.027,118.323,63.621z"/>
          </g>
        </g>
        <g>
          <g>
            <circle fill="#29ABE2" cx="132.25" cy="47" r="2.75"/>
          </g>
        </g>
        <g>
          <g>
            <circle fill="#29ABE2" cx="81.25" cy="47" r="2.75"/>
          </g>
        </g>
        <g>
          <g>
            <path d="M155.867,16.064c-18.657,21.797,7.975,32.194,15.714,19.56c7.739-12.635,6.966-22.845,1.943-26.194
              C168.449,6.046,158.079,13.483,155.867,16.064z M164.409,18.082c1.774-2.146,3.87-3.595,5.433-2.312
              c2.569,2.111-0.14,7.572-0.14,7.572L164.409,18.082z"/>
          </g>
        </g>
        <g>
          <g>
            <path d="M57.102,4.364c-5.969,0.905-10.997,9.824-9.344,24.547c1.654,14.724,30.181,16.597,22.523-11.054
              C69.374,14.582,63.132,3.45,57.102,4.364z M54.667,18.583c0,0-0.14-6.094,3.083-6.917c1.958-0.5,3.242,1.701,3.94,4.396
              L54.667,18.583z"/>
          </g>
        </g>
      </svg>
    )
  }
};

const icons = {
  triangle: {
    shape: (
      <Cat2/>
    ),
    viewBox: `0 0 30 30`,
  },
  circle: {
    shape: (
      <Cat1/>
    ),
    viewBox: `0 0 30 30`,
  },
  arrowUp: {
    shape: (
      <Cat3/>
    ),
    viewBox: `0 0 30 42`,
  },
  upDown: {
    shape: (
      <Cat4/>
    ),
    viewBox: `0 0 30 44.58`,
  },
  box: {
    shape: (
      <Cat2/>
    ),
    viewBox: `0 0 30 30`,
  },
  hexa: {
    shape: (
      <Cat2/>
    ),
    viewBox: `0 0 30 30`,
  },
  cross: {
    shape: (
      <Cat4/>
    ),
    viewBox: `0 0 100 100`,
  },
}

type IconType = "triangle" | "circle" | "arrowUp" | "upDown" | "box" | "hexa" | "cross"

type SVGProps = {
  stroke?: boolean
  color?: string | number | any
  width: number
  icon: IconType
  left: string
  top: string
  hiddenMobile?: boolean
}

const SVG = ({ stroke = false, color = ``, width, icon, left, top, hiddenMobile = false }: SVGProps) => (
  <svg
    sx={{
      position: `absolute`,
      stroke: stroke ? `currentColor` : `none`,
      fill: stroke ? `none` : `currentColor`,
      display: hiddenMobile ? hidden : `block`,
      color,
      width,
      left,
      top,
      maxWidth: (100 - left.slice(0, -1)) + '%'
    }}
    viewBox={icons[icon].viewBox}
  >
    {icons[icon].shape}
  </svg>
)

export default SVG
