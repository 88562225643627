const pressData = [
{
  tags:["UberCheats", "Featured"],
  title:"The delivery rider who took on his faceless boss",
  whatisit:"Article",
  date:"2024-03-15",
  links:[{name: "Financial Times", url: "https://www.ft.com/content/84140a48-2465-46b7-9185-5d1d89dbf70c"}, {name: "Book", url: "https://us.macmillan.com/books/9781250339003/codedependent"}],
  text:"A chapter from the book \"Code Dependent: Living in the Shadow of AI\"",
  imgsrc:"/press/ft-ubercheats.jpg"
},
{
  tags:["Personal"],
  title:"2024 'State of our Streets' released in Lawrenceville",
  whatisit:"Article",
  date:"2024-02-09",
  links:[{name: "WPXI", url: "https://www.wpxi.com/news/local/2024-state-our-streets-released-lawrenceville/BFUOSD7IPZAJHGZEBTS57EJRWA/"}, {name: "Next Pittsburgh", url: "https://nextpittsburgh.com/latest-news/lawrenceville-urged-to-improve-pedestrian-safety/"}],
  text:"On Better Streets Lawrenceville's State of our Streets 2024 Report.",
  imgsrc:"/press/wpxi-bsl-2024.jpg"
},

{
  tags:["Featured", "Personal"],
  title:"Our Train-Themed Wedding!",
  whatisit:"Article",
  date:"2023-03-13",
  links:[{name: "People Magazine", url: "https://people.com/connection-on-train-leads-to-themed-wedding-real-life-love-7965029"}, {name: "SFGate", url: "https://www.sfgate.com/local/article/bay-area-couple-hosts-bart-themed-wedding-oakland-18307515.php"}, {name: "bart.gov", url: "https://www.bart.gov/news/articles/2023/news20230816"}],
  text:"I met my partner on BART, and seven years and one day later, we celebrated BART and public transit at our wedding.",
  imgsrc:"/press/wedding.jpg"
},

{
  tags:["dashcam.bike"],
  title:"technical.ly",
  whatisit:"Article",
  date:"2023-03-13",
  links:[{name: "Lessons from a solopreneur", url: "https://technical.ly/startups/dashcam-for-your-bike-armin-samii-rustbuilt/"}],
  text:"Lessons from a solopreneur: 10 questions with Dashcam for Your Bike founder Armin Samii.",
  imgsrc:"/press/technically-logo-icon.svg"
},

{
  tags:["dashcam.bike"],
  title:"technical.ly",
  whatisit:"Article",
  date:"2023-02-16",
  links:[{name: "How could Pittsburgh attract more tech workers and companies?", url: "https://technical.ly/startups/how-pittsburgh-attract-tech-workers-founders-racial-equity-infrastructure/"}],
  text:"Investment in infrastructure and racial equity.",
  imgsrc:"/press/technically-logo-icon.svg"
},

{
  tags:["dashcam.bike"],
  title:"technical.ly",
  whatisit:"Article",
  date:"2023-01-26",
  links:[{name: "RealLIST Startups 2023", url: "https://technical.ly/startups/reallist-pittsburgh-2023/"}],
  text:"Listed as one of Pittsburgh's top tech companies of 2023",
  imgsrc:"/press/reallist.jpg"
},

{
  tags:["Featured", "dashcam.bike"],
  title:"WBUR",
  whatisit:"Article",
  date:"2023-01-10",
  links:[{name: "Should cyclists hug the curb or ride in the car lane?", url: "https://www.wbur.org/hereandnow/2023/01/10/bikes-curb-or-car-lane"}],
  text:"Speaking with Here & Now about why bicyclists are given a false choice between two bad options, and should instead advocate for better infrastructure.",
  imgsrc:"/press/hereandnow.png"
},

{
  tags:["Featured", "dashcam.bike"],
  title:"Pittsburgh City Paper",
  whatisit:"Article",
  date:"2022-12-22",
  links:[{name: "Advocates celebrate city funding for Penn Avenue bike lane upgrades", url: "https://www.pghcitypaper.com/pittsburgh/advocates-celebrate-city-funding-for-penn-avenue-bike-lane-upgrades/Content?oid=23023598"}],
  text:"On how dashcam.bike helped BikePGH win funding to protect one of Pittsburgh's flagship bike lanes.",
  imgsrc:"/press/citypaper.jpg"
},

{
  tags:["dashcam.bike", "Personal"],
  title:"CityCast Pittsburgh",
  whatisit:"Podcast",
  date:"2022-10-12",
  links:[{name: "Who Will Respect Bike Lanes If Even Police Won't?", url: "https://pod.link/citycastpittsburgh/episode/08ce52bb7de662995c1ff4b9f15f3cff"}],
  text:"Podcast episode about the importance of better bike infrastructure, based on an incident captured with dashcam.bike.",
  imgsrc:"/press/citycast.jpg"
},

{
  tags:["Personal"],
  title:"City Paper PGH",
  whatisit:"Article",
  date:"2022-10-07",
  links:[{name: "Video shows police officer belittling resident when confronted on illegal parking", url: "https://www.pghcitypaper.com/pittsburgh/video-shows-police-officer-belittling-resident-when-confronted-on-illegal-parking/Content?oid=22562455"}],
  text:"I captured this interaction with the dashcam.bike app, though this article shares my personal story.",
  imgsrc:"/press/citypaper.jpg"
},

{
  tags:["Personal"],
  title:"technical.ly",
  whatisit:"Article",
  date:"2022-10-19",
  links:[{name: "RealLIST Engineers 2022: These 16 tech leaders are changing Pittsburgh’s code", url: "https://technical.ly/software-development/reallist-engineers-2022-pittsburgh/"}],
  text:"It is claimed that I am an influencer of code. Who am I to deny it? #influencer.",
  imgsrc:"/press/reallist.jpg"
},

{
  tags:["Personal"],
  title:"WPXI",
  whatisit:"Video",
  date:"2022-09-16",
  links:[{name: "Better Streets Lawrenceville working to make streets safer for all", url: "https://www.wpxi.com/news/local/better-streets-lawrenceville-working-make-streets-safer-all/GB3RBDHWE5HLHNVKX3B4RUGEPM/"}],
  text:"We organized Park(ing) Day 2022 in Lawrenceville, and WPXI spoke to Better Streets Lawrenceville about improving street safety. I appear around 1:45 to talk about improvements to Main & Butler.",
  imgsrc:"/press/wpxi-bsl-2023.jpg"
},
{
  tags:["dashcam.bike"],
  title:"technical.ly",
  whatisit:"Article",
  date:"2022-09-22",
  links:[{name: "Dashcam for your Bike unveils Hazard Map to showcase bike lane hazards, promote safety", url: "https://technical.ly/civic-news/dashcam-for-your-bike-hazard-map-pittsburgh-bike-safety/"}],
  text:"",
  imgsrc:"/press/technically-hazard-map.jpg"
},

{
  tags:["dashcam.bike"],
  title:"The Business Journals",
  whatisit:"Video",
  date:"2022-09-23",
  links:[{name: "Dashcam for your Bike identifies city streets with most bike lane obstructions", url: "https://www.bizjournals.com/pittsburgh/inno/stories/profiles/2022/09/23/dashcam-for-your-bike-lane-obstructions-report.html"}],
  text:"Article from The Business Journals about dashcam.bike's Hazard Map.",
  imgsrc:"/press/bizjournals-hazard-map.png"
},

{
  tags:["Featured", "dashcam.bike"],
  title:"WPXI",
  whatisit:"Video",
  date:"2022-10-12",
  links:[{name: "City of Pittsburgh partners with app to make travel safer for cyclists", url: "https://www.wpxi.com/news/local/city-pittsburgh-partners-with-app-make-travel-safer-cyclists/6DC6TDXTY5F2LJ5FVI7DLG2O74/"}],
  text:"TV Interview with WPXI Pittsburgh discussing how dashcam.bike makes streets safer.",
  imgsrc:"/press/wpxi-safe-in-bike-lanes.jpg"
},

{
  tags:["dashcam.bike"],
  title:"PGH Lab Cohort 7.0",
  imgsrc:"/press/pghlab.jpg",
  whatisit:"dashcam.bike",
  date:"2022-03-10",
  links:[{name: "Announcing PGH Lab’s 7.0 Cohort", url: "https://medium.com/department-of-innovation-performance/pgh-labs-7-0-cohort-5d9da73cdcf5"}],
  text:""
},

{
  tags:["dashcam.bike"],
  title:"PittsburghInno",
  whatisit:"Article",
  date:"2021-12-28",
  links:[{name: "PGH Lab announces seventh cohort of startups", url: "https://www.bizjournals.com/pittsburgh/inno/stories/news/2021/12/28/pgh-lab-announces-seventh-cohort-of-startups.html"}],
  text:"An early announcement of PGH Lab Cohort 7.0"
},

{
  tags:["dashcam.bike"],
  title:"technical.ly",
  whatisit:"Article",
  date:"2022-02-23",
  links:[{name: "What does ‘local’ mean now for Pittsburgh startup founders?", url: "https://technical.ly/civic-news/pittsburgh-startup-founders-what-local-means-now/"}],
  text:""
},

{
  tags:["dashcam.bike"],
  title:"RealLIST Startups",
  whatisit:"dashcam.bike",
  date:"2022-01-25",
  links:[{name: "RealLIST Honorable Mention", url: "https://technical.ly/professional-development/pittsburgh-reallist-startups-2022/"}],
  text:""
},

{
  tags:["Featured", "RCV"],
  title:"Fox 5 New York",
  imgsrc:"/press/fox5ny.jpg",
  whatisit:"Ranked Choice Voting",
  date:"2021-06-03",
  links:[{name: "Video", url: "https://www.fox5ny.com/video/940333"}],
  text:"TV Interview with Fox 5 New York. Soundbite at 2:45 into the video, explaining the benefits of Ranked Choice Voting."
},

{
  tags:["RCV"],
  title:"RCV Tabulator Release",
  whatisit:"Ranked Choice Voting",
  date:"2021-03-12",
  links:[{name: "Video", url: "https://www.youtube.com/watch?v=lXH6a01zojs"}],
  text:"Interview for the release of the Ranked Choice Voting Resource Center Universal Tabulator."
},

{
  tags:["RCV"],
  title:"Gothamist",
  whatisit:"Ranked Choice Voting",
  date:"2021-06-30",
  links:[{name: "Article", url: "https://gothamist.com/news/board-elections-releases-updated-ranked-choice-tally"}],
  text:"Gothamist used RCVis for their official 2021 NYC primary reporting. I worked with Gothamist to create the visualizations in this article."
},

{
  tags:["RCV"],
  title:"Ballotpedia",
  imgsrc:"/press/ballotpedia.jpeg",
  whatisit:"Ranked Choice Voting",
  date:"2021-06-30",
  links:[{name: "Website", url: "https://ballotpedia.org/June_22,_2021,_election_results"}],
  text:"RCVis was used on Ballotpedia to visualize the results of the New York City Mayoral Primary."
},

{
  tags:["RCV"],
  title:"City & State PA",
  whatisit:"Ranked Choice Voting",
  date:"2022-07-05",
  links:[{name: "Making the case for second place: Ranked-choice voting explained", url: "https://www.cityandstatepa.com/politics/2022/07/making-case-second-place-ranked-choice-voting-explained/368971/"}],
  text:"In which I'm quoted discussing how RCV could have helped in the 2022 PA Primaries."
},

{
  tags:["RCV"],
  title:"Bucks County Herald",
  whatisit:"Ranked Choice Voting",
  date:"2022-05-19",
  links:[{name: "Ranked Choice Voting gives voters more choice Crowded primary fields leave voters unsatisfied and unrepresented", url: "https://buckscountyherald.com/stories/ranked-choice-voting-gives-voters-more-choice-crowded-primary-fields-leave-voters-unsatisfied-and,17158"}],
  text:"Press Release on the 2022 Primaries, and how RCV can help."
},

{
  tags:["UberCheats"],
  title:"Salon",
  whatisit:"UberCheats",
  date:"2020-08-20",
  links:[{name: "Programmers say Uber Eats is systematically underpaying their workers.", url: "https://www.salon.com/2020/08/20/programmers-say-uber-eats-is-systematically-underpaying-their-workers/"}],
  text:""
},

{
  tags:["UberCheats"],
  title:"Business Insider",
  whatisit:"UberCheats",
  date:"2020-08-20",
  links:[{name: "UberEats could be underpaying delivery drivers on 21% of trips, according to a programmer who reportedly built a tool that found the app was lowballing the miles that drivers traveled", url: "https://www.businessinsider.com/uber-eats-driver-who-scraped-data-alleges-wage-theft-report-2020-8"}],
  text:""
},

{
  tags:["UberCheats"],
  title:"Tech Workers Coalition",
  imgsrc:"/press/twc.jpg",
  whatisit:"UberCheats",
  date:"2020-08-20",
  links:[{name: "UberEats could be underpaying delivery drivers on 21% of trips, according to a programmer who reportedly built a tool that found the app was lowballing the miles that drivers traveled", url: "https://news.techworkerscoalition.org/2020/09/04/issue-10/"}],
  text:""
},

{
  tags:["UberCheats"],
  title:"Screen Rant",
  whatisit:"UberCheats",
  date:"2020-08-21",
  links:[{name: "UberCheats Chrome Extension Checks For Uber Eats Underpayments", url: "https://screenrant.com/ubercheats-chrome-extension-uber-eats-drivers/"}],
  text:""
},



{
  tags:["UberCheats"],
  title:"Digital Trends [ES]",
  whatisit:"UberCheats",
  date:"2020-08-21",
  links:[{name: "Denuncian que UberEats le paga menos a sus repartidores", url: "https://es.digitaltrends.com/negocios/ubereats-paga-menos-repartidores/"}],
  text:""
},



{
  tags:["UberCheats"],
  title:"Tech Worker’s Coalition",
  whatisit:"UberCheats",
  date:"2020-09-12",
  links:[{name: "Issue 10: Wages of Short-Lived Startups", url: "https://news.techworkerscoalition.org/2020/09/04/issue-10/"}],
  text:""
},



{
  tags:["UberCheats"],
  title:"VICE",
  whatisit:"UberCheats",
  date:"2021-02-18",
  links:[{name: "Uber Shuts Down App That Told Drivers If Uber Underpaid Them", url: "https://www.vice.com/en/article/wx8yvm/uber-shuts-down-app-that-lets-users-know-how-badly-theyve-been-cheated"}],
  text:""
},



{
  tags:["UberCheats"],
  title:"Daily Mail",
  whatisit:"UberCheats",
  date:"2021-02-18",
  links:[{name: "Google pulls UberCheats app which lets drivers check their tips to see if they are being underpaid by the ride sharing firm, after Uber files false trademark against the Chrome extension", url: "https://www.dailymail.co.uk/sciencetech/article-9274789/Uber-gets-app-pulled-Google-lets-drivers-underpaid.html"}],
  text:""
},



{
  tags:["UberCheats"],
  title:"CNET Roadshow",
  whatisit:"UberCheats",
  date:"2021-02-18",
  links:[{name: "Google removes app that calculated if Uber drivers were underpaid", url: "https://www.cnet.com/roadshow/news/uber-drivers-underpaid-app-google-remove/"}],
  text:""
},



{
  tags:["UberCheats"],
  title:"PC Mag",
  whatisit:"UberCheats",
  date:"2021-02-19",
  links:[{name: "Uber Gets UberCheats Pulled From Google", url: "https://www.pcmag.com/news/uber-gets-ubercheats-pulled-from-google"}],
  text:""
},



{
  tags:["UberCheats"],
  title:"Richard Stallman’s Blog",
  whatisit:"UberCheats",
  date:"2021-02-21",
  links:[{name: "How Uber cheats drivers", url: "https://www.stallman.org/archives/2021-jan-apr.html#25_February_2021_(How_Uber_cheats_drivers)"}],
  text:""
},



{
  tags:["Featured", "UberCheats"],
  title:"Wired",
  imgsrc:"/press/wired.jpg",
  whatisit:"UberCheats",
  date:"2021-02-24",
  links:[{name: "Gig Workers Gather Their Own Data to Check the Algorithm’s Math", url: "https://www.wired.com/story/gig-workers-gather-data-check-algorithm-math/"}],
  text:"Wired article featuring UberCheats and other software used to make gig work more fair."
},



{
  tags:["Featured", "UberCheats"],
  title:"Wall Street Journal",
  imgsrc:"/press/ubercheats.jpg",
  whatisit:"UberCheats",
  date:"2021-06-23",
  links:[{name: "Uber, Lyft Drivers Race to Apps That Make Contract Work a Better Gig", url: "https://www.wsj.com/articles/uber-lyft-drivers-race-to-apps-that-make-contract-work-a-better-gig-11624449601?st=jpr6lls5bpffaws&reflink=desktopwebshare_permalink"}],
  text:""
},


];

export default pressData;
