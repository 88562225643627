const projectsData = [
{
  tags:["Featured", "Bike Safety"],
  title:"dashcam.bike",
  imgsrc:"/teasers/dashcam-icon.png",
  whatisit:"Bike Safety Company",
  date:"2022-present",
  links:[{name: "Website", url: "https://dashcam.bike"},
         {name: "Twitter", url: "https://twitter.com/dashcambike"},
         {name: "Instagram", url: "https://instagram.com/dashcambike"},
         {name: "LinkedIn", url: "https://www.linkedin.com/company/79463047/"}],
  text:"Tool for advocates to fight for safer streets by recording bike rides and reporting hazards before they get worse. Our stabilizing phone mount and app work together to create high-quality footage with just a phone camera."
},

{
  tags:["Bike Safety"],
  title:"Hazard Reporting",
  imgsrc:"/press/bizjournals-hazard-map.png",
  whatisit:"Live 311 Reporting Map",
  date:"2022",
  links:[{name: "Hazard Map", url: "https://dashcam.bike/maps/"}],
  text:"Aggregating the 311 reports from the Dashcam app to Pittsburgh and DC city governments, and putting them in an interactive, filterable map"
},

{
  tags:["Featured", "Activism", "Visualizations"],
  title:"RCVis",
  imgsrc:"/teasers/rcvis-demo.jpg",
  whatisit:"RCV Visualization Tool",
  date:"2019-present",
  links:[{name: "Website", url: "https://www.rcvis.com"}, {name: "Github", url: "https://github.com/artoonie/rcvis"}],
  text:"In a Ranked-Choice Voting election, if your top candidate doesn't win the election, your vote still counts: your vote is transferred to your next pick. RCVis visualizes what happens in each round of an RCV election."
},

{
  tags:["Featured", "Activism"],
  title:"UberCheats",
  imgsrc:"/teasers/ubercheats.png",
  whatisit:"Chrome Extension",
  date:"2020",
  links:[{name: "Github", url: "https://github.com/artoonie/ubercheats"},
         {name: "Press: Wired", url: "https://www.wired.com/story/gig-workers-gather-data-check-algorithm-math/"},
         {name: "Press: Salon", url: "https://www.salon.com/2020/08/20/programmers-say-uber-eats-is-systematically-underpaying-their-workers/"}],
  text:"UberEATS has a bug that led to underpayments for gig workers. UberCheats allows couriers to detect when they have been underpaid."
},

{
  tags:["Activism"],
  title:"Baby Trump",
  imgsrc:"/teasers/babytrump.png",
  whatisit:"Twitter Bot",
  date:"2020",
  links:[{name: "Github", url: "https://github.com/artoonie/babytrump"},
         {name: "Twitter", url: "https://twitter.com/realBigBoyTrump"},
         {name: "Medium", url: "https://medium.com/@armin.samii/a-dm-based-database-for-twitterbots-on-aws-lambda-1d4a804ca209"}],
  text:"This twitterbot translates his tweets into infantese to make them more intelligible."
},

{
  tags:["Featured", "Visualizations"],
  title:"Argo AI Visualizations",
  imgsrc:"/teasers/argoai.jpg",
  whatisit:"Video",
  date:"2020-10-30",
  links:[{name: "Video", url: "https://www.youtube.com/watch?v=LpVQqpxSEWc"}],
  text:"An example of the visualizations we built at Argo. In my opinion, Argo has the best dataviz of any autonomous vehicle company. I'm biased, but you can check it and decide for yourself."
},

{
  tags:["Activism"],
  title:"Red Friends, Blue Friends",
  imgsrc:"/teasers/rfbf.png",
  whatisit:"Website",
  date:"2017",
  links:[{name: "Github", url: "https://github.com/artoonie/RedStatesBlueStates"},
         {name: "Website", url: "http://www.redfriendsbluefriends.com"}],
  text:"Senators only listen to their constituents. This lets you find your Facebook friends represented by specific Senators to influence Senate votes.<br />"
},

{
  tags:["Activism"],
  title:"Transcroobie",
  imgsrc:"/teasers/transcroobie.png",
  whatisit:"Website",
  date:"2016",
  links:[{name: "Github", url: "https://github.com/artoonie/transcroobie"}],
  text:"Human-corrected speech-to-text, combining Amazon Mechanical Turk with Google Speech API. Deprecated."
},

{
  tags:["Visualizations"],
  title:"Log Stream Dataviz",
  imgsrc:"/teasers/logvis.png",
  whatisit:"Research Application",
  date:"2014",
  links:[{name: "PDF", url: "research/papers/logvis.pdf"},
         {name: "Poster", url: "research/papers/logvis-poster.pdf"}],
  text:"An interface to turn system logs into visual dashboards."
},

{
  tags:[],
  title:"Vocabulary Quizzer",
  imgsrc:"/teasers/vocab-quizzer.png",
  whatisit:"Flash Application (long live ActionScript)",
  date:"2010",
  text:"Created to help me study for the GRE Verbal Exam. Has gone wherever old Flash apps go to rest."
}
];

export default projectsData;
