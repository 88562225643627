import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div id="top"></div>
    <div style={{
      float: `left`,
      textAlign: `center`
    }}>
  <img src="/headshot-half-body.jpg" style={{
        marginRight: `auto`,
        marginLeft: `auto`,
        maxWidth: `80%`
      }} alt="My photo, wearing rainbow overalls" />
    </div>
    <h1>{`Armin Samii`}</h1>
    <p>{`Just a guy on a bicycle, yelling about ranked-choice voting.`}</p>
    <div style={{
      fontSize: `1.0em`
    }}>
  | <a href="#about">About</a> | <a href="#Projects">Projects</a> | <a href="#Press">Press</a> | <a href="#Research & Patents">Research & Patents</a> | <a href="#Bartending">Bartending</a> | <a href="#contact">Contact</a> | 
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      