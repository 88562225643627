import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div id="contact"></div>
    <h2>{`Get in touch`}</h2>
    <p>{`I am Very Online™ and only a month or two behind the Good Good Memes. I even had a TikTok once.
I recommend you follow me on `}<a href="https://twitter.com/arminsamii">{`Twitter`}</a>{`, though feel free to choose another presence:`}</p>
    <div style={{
      "marginTop": "100px",
      "textAlign": "center"
    }}>
      <a href="https://medium.com/@armin.samii"><img src="/socialmedia/medium-icon.png" width="50" alt="Medium" /></a>
      <a href="https://twitter.com/arminsamii"><img src="/socialmedia/Twitter-icon.png" width="50" alt="Twitter" /></a>
      <a href="http://instagram.com/artoonie"><img src="/socialmedia/Instagram-icon.png" width="50" alt="Instagram" /></a>
      <a href="http://www.last.fm/user/artoonie"><img src="/socialmedia/Lastfm-icon.png" width="50" alt="last.fm" /></a>
      <a href="https://www.youtube.com/user/Artoonie"><img src="/socialmedia/Youtube-icon.png" width="50" alt="YouTube" /></a>
      <p>{`and at `}<a parentName="p" {...{
          "href": "mailto:hello@arminsamii.com"
        }}>{`hello@arminsamii.com`}</a>{`.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      