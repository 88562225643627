/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState } from 'react';
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import SVG from "./svg"
import { UpDown, UpDownWide } from "@lekoarts/gatsby-theme-cara/src/styles/animations"
// @ts-ignore
import bartendingData from "../sections/bartending-data";
import pressData from "../sections/press-data";
import researchData from "../sections/research-data";
import projectsData from "../sections/projects-data";
import {CustomProjectCardList} from "../components/project-card";
import ProjectCardTagSelector from "../components/project-card-tag-selector";

function getComponentForCardType(cardType) {
  if (cardType == 'Bartending') {
    return bartendingData;
  }
  if (cardType == 'Projects') {
    return projectsData;
  }
  if (cardType == 'Research & Patents') {
    return researchData;
  }
  if (cardType == 'Press') {
    return pressData;
  }
}

function getTagListFor(listOfProps) {
  const listOfListOfTags = listOfProps.map(props => props.tags);
  const flattenedTags = listOfListOfTags.flat(1);
  const setOfTags = new Set(flattenedTags);
  const uniqueTagList = Array.from(setOfTags);

  if (uniqueTagList.indexOf('Featured') > 0) {
      uniqueTagList.splice(uniqueTagList.indexOf('Featured'), 1);
      uniqueTagList.unshift('Featured');
  }
  uniqueTagList.unshift('All');

  return uniqueTagList;
}

const Cards = ({ cardType }: { }) => {
  const dataForThisProp = getComponentForCardType(cardType);
  const [currentTag, setCurrentTag] = useState('Featured');
  const uniqueTagList = getTagListFor(dataForThisProp);

  return (
  <div>
    <Divider
      bg="linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%)"
      sx={{ clipPath: `polygon(0 15%, 100% 25%, 100% 85%, 0 75%)` }}
    />
    <Content>
      <Inner>
        <h1 id={cardType}>{cardType}</h1>
        <div style={{marginBottom: 20}}>
            <ProjectCardTagSelector
                currentTag={currentTag}
                setCurrentTag={setCurrentTag}
                tags={uniqueTagList}>
            </ProjectCardTagSelector>
        </div>


        <div
          sx={{
            display: `grid`,
            gridGap: [4, 4, 4, 5],
            gridTemplateColumns: [`1fr`, `1fr`, `repeat(2, 1fr)`],
            h2: { gridColumn: `-1/1`, color: `white !important` },
          }}
        >
            <CustomProjectCardList listOfProps={dataForThisProp} currentTag={currentTag}/>
        </div>
      </Inner>
    </Content>
    <Divider>
      <UpDown>
        <SVG icon="box" width={6} color="icon_brightest" left="85%" top="75%" />
        <SVG icon="upDown" width={8} color="icon_teal" left="70%" top="20%" />
        <SVG icon="triangle" width={8} stroke color="icon_orange" left="25%" top="5%" />
        <SVG icon="circle" hiddenMobile width={24} color="icon_brightest" left="17%" top="60%" />
      </UpDown>
      <UpDownWide>
        <SVG icon="arrowUp" hiddenMobile width={16} color="icon_green" left="20%" top="90%" />
        <SVG icon="triangle" width={12} stroke color="icon_brightest" left="90%" top="30%" />
        <SVG icon="circle" width={16} color="icon_yellow" left="70%" top="90%" />
        <SVG icon="triangle" hiddenMobile width={16} stroke color="icon_teal" left="18%" top="75%" />
        <SVG icon="circle" width={6} color="icon_brightest" left="75%" top="10%" />
        <SVG icon="upDown" hiddenMobile width={8} color="icon_green" left="45%" top="10%" />
      </UpDownWide>
      <SVG icon="circle" hiddenMobile width={6} color="icon_brightest" left="4%" top="20%" />
      <SVG icon="circle" width={12} color="icon_pink" left="80%" top="60%" />
      <SVG icon="box" width={6} color="icon_orange" left="10%" top="10%" />
      <SVG icon="box" width={12} color="icon_yellow" left="29%" top="26%" />
      <SVG icon="hexa" width={16} stroke color="icon_red" left="75%" top="30%" />
      <SVG icon="hexa" width={8} stroke color="icon_yellow" left="80%" top="70%" />
    </Divider>
  </div>
  )
}

export default Cards
