const bartendingData = [
{
  title:"Murder Mystery Party",
  imgsrc:"bartending/hattersball2018-thumb.png",
  whatisit:"Cocktail Menu",
  date:"2018",
  tags: ['Featured', 'Birthday'],
  links:[{name: "Menu [pdf]", url: "bartending/hattersball2018.pdf"}],
  text: "Each murder mystery participant was themed after a specific color, Clue-style."
},

{
  title:"birthday party",
  imgsrc:"bartending/friend2017.jpg",
  whatisit:"Cocktail Menu",
  date:"2017",
  tags: ['Featured', 'Birthday'],
  links:[{name: "Menu [png]", url: "bartending/friend2017.pdf"}, {name: "Recipe Sheet [txt]", url: "bartending/friend2017.txt"}],
  text: "Each drink is based on an item in his resume."
},

{
  title:"Avametric Holiday Party",
  imgsrc:"bartending/avametricparty2015-thumb.jpg",
  whatisit:"Cocktail Menu",
  date:"2015",
  tags: ['Featured', 'Office'],
  links:[{name: "Menu [png]", url: "bartending/avametricparty2015.png"}, {name: "Recipe Sheet [txt]", url: "bartending/avametricparty2015-notes.txt"}],
  text: "At the time of this party, we had two teams, Team Green and Team Hippocorn, as well as a leaky roof."
},

{
  title:"CSGSA Masked Ball",
  imgsrc:"bartending/csgsa-maskedball2013-thumb.jpg",
  whatisit:"Cocktail Menu",
  date:"2013",
  tags: ['Featured', 'Office'],
  links:[{name: "Menu [png]", url: "bartending/csgsa-maskedball2013.png"}, {name: "Recipe Sheet [pdf]", url: "bartending/csgsa-maskedball2013-notes.pdf"}],
  text: "CSGSA Halloween party, for Berkeley EECS grad students."
},

{
  title:"CSGSA Halloween Party",
  imgsrc:"bartending/csgsa-halloween2012-thumb.jpg",
  whatisit:"Cocktail Menu",
  date:"2012",
  tags: ['Office'],
  links:[{name: "Menu [png]", url: "bartending/csgsa-halloween2012.png"}, {name: "Recipe Sheet [txt]", url: "bartending/csgsa-halloween2012-notes.txt"}],
  text: "The presidential election was around the corner when the CSGSA (Computer Science Graduate Student Association at Berkeley) had its halloween party."
}]

export default bartendingData;
