const researchData = [
{
  title:"Generation of Improved Clothing Models",
  imgsrc:"/teasers/avametric-patent-2.jpg",
  whatisit:"Patent",
  date:"2020",
  tags:['Patent'],
  links:[{name: "Google Patents", url: "https://patents.google.com/patent/US20200250892A1"}],
  text:"US Patent 20200250892 A1 (pending)<br/>"
},

{
  title:"Clothing Model Generation and Display System",
  imgsrc:"/teasers/avametric-patent.png",
  whatisit:"Patent",
  date:"2019",
  tags:['Patent'],
  links:[{name: "Google Patents", url: "https://patents.google.com/patent/US20190130649A1"}],
  text:"US Patent 20190130649 A1 (pending)<br/>"
},

{
  title:"Using adaptive meshes in fixed-mesh pipelines",
  imgsrc:"/teasers/adaptive-resampling.png",
  whatisit:"Poster",
  date:"2015",
  tags:['Academic'],
  links:[{name: "Project Page", url: "http://graphics.berkeley.edu/papers/Brown-RAC-2015-08/"}],
  text:"<em>\"Resampling Adaptive Cloth Simulations onto Fixed-Topology Meshes\"</em><br/>\
       George Brown, <strong>Armin Samii</strong>, James F. O'Brien, and Rahul Narain<br/>\
       ACM SIGGRAPH/Eurographics Symposium on Computer Animation 2015"
},

{
  title:"A Perceptually Based Model of Visual Adaptation",
  imgsrc:"/teasers/lowlight.png",
  whatisit:"Thesis",
  date:"2014",
  tags:['Featured', 'Thesis', 'Academic'],
  links:[{name: "PDF", url: "/research/papers/lowlight.pdf"}],
  text:"A perceptual model that accounts for the time-varying changes to perceived color and brightness that occur due to time-varying adaptation and the transition between cone- and rod-mediated vision."
},

{
  title:"Aesthetics-driven automatic cropping",
  imgsrc:"/teasers/crop-paper.png",
  whatisit:"Paper",
  date:"2014",
  tags:['Featured', 'Academic'],
  links:[{name: "PDF", url: "research/papers/crop-paper.pdf"}],
  text:"<em>\"Data-Driven Automatic Cropping for Semantic Composition Search\"</em><br/>\
  <strong>Armin Samii</strong>, Zhe Lin, and Radomir Měch<br/>\
  Computer Graphics Forum 2014"
},

{
  title:"Distance Metric for Image Comparison",
  imgsrc:"/teasers/crop-patent.png",
  whatisit:"Patent",
  date:"2014",
  tags:['Patent'],
  links:[{name: "Google Patents", url: "http://www.google.com/patents/US20140169684"}],
  text:"US Patent 20140169684 A1"
},

{
  title:"ARCSIM: Adaptive Remeshing for Cloth Sim",
  imgsrc:"/teasers/adaptive-cloth.jpg",
  whatisit:"Paper",
  date:"2012",
  tags:['Featured', 'Academic'],
  links:[{name: "Project Page", url: "http://graphics.berkeley.edu/papers/Narain-AAR-2012-11"}],
  text:"<em>\"Adaptive Anisotropic Remeshing for Cloth Simulation\"</em><br/>\
  Rahul Narain, <strong>Armin Samii</strong>, and James F. O'Brien<br/>\
  ACM SIGGRAPH Asia 2012"
},

{
  title:"Image Sorter",
  imgsrc:"/teasers/imagesorter.png",
  whatisit:"Thesis",
  date:"2010",
  tags:['Featured', 'Thesis'],
  links:[{name: "UCSC Senior Thesis", url: "http://www.soe.ucsc.edu/research/technical-reports/ucsc-soe-11-18"}],
  text:"Sorts through your photo library. Automatically detects low-quality and repetitive photos, sorting photographs in a way that prioritizes both uniqueness and quality. Winner of the <a href='https://undergrad.soe.ucsc.edu/honors/2011-2012'>Huffman Prize</a>."
},

{
  title:"Lightroom-backed image stylization",
  imgsrc:"/teasers/adm.png",
  whatisit:"Research Application",
  date:"2011",
  tags:['Academic'],
  links:[{name: "Slides", url: "research/papers/aesthetics-slides.pdf"}, {name: "PDF", url: "research/papers/computational-aesthetics.pdf"}],
  text:"Stylizing images in a reproducible way, showing you which Lightroom sliders to toggle. Trained on six photgraphers' Lightroom catalogs."
},

{
  title:"Garden Path",
  imgsrc:"/teasers/garden-path.png",
  whatisit:"Research Application",
  date:"2011",
  tags:['Academic'],
  links:[{name: "Project Page", url: "research/gardenpath/index.html"},,
          {name: "Publication", url: "https://www.researchgate.net/publication/233789442_Metacontrast_masking_is_processed_before_grapheme-color_synesthesia"}],
  text:"For some pairs of flickering gradients, you will see a strip in the center where it appears to be static, called the \"Garden Path\". I built a simple Qt+OpenGL app to generate Garden Paths."
}
]

export default researchData;
